<script>
import Layout from "../../layouts/table";
import {mapGetters} from "vuex";

/**
 * Profile component
 */
export default {
    components: {
        Layout,
    },
    data() {
        return {
            selectedApiAccount: null,
            smsPagination: {
                perPage: 10,
                currentPage: 1,
                sortBy: 'date',
                sortDesc: true
            },
            userPagination: {
                perPage: 10,
                currentPage: 1,
                sortBy: 'date',
                sortDesc: true
            }
        };
    },
    computed: {
        ...mapGetters({
            items: 'stat/sms',
            users: 'stat/users',
            apiAccounts: 'apiAccount/list',
            smsLoading: 'stat/smsLoading',
            apiUsersLoading: 'stat/apiUsersLoading',
        }),
        rows: function () {
            return this.items && this.items.length;
        },
        userRows: function () {
            return this.users && this.users.length;
        },
        fields: function () {
            return [
                {
                    key: "yearmonth",
                    label: this.$t('profile.date'),
                    thClass: 'gridjs-th',
                    sortable: true
                },
                {
                    key: "event_count",
                    label: this.$t('profile.event_count'),
                    thClass: 'gridjs-th border-left-0',
                    tdClass: 'text-end',
                    sortable: true
                },
                {
                    key: "sms_count",
                    label: this.$t('profile.sms_count'),
                    thClass: 'gridjs-th border-left-0',
                    tdClass: 'text-end',
                    sortable: true
                },
                {
                    key: "actions",
                    label: '',
                    thClass: 'gridjs-th',
                    tdClass: 'text-end',
                    sortable: true
                },
            ];
        },
    },
    methods: {
        download: function (date) {
            this.$store.dispatch('stat/downloadSMSStatistics', date);
        }
    },

    watch: {
        selectedApiAccount: function (value) {
            this.$store.dispatch('stat/fetchApiUsersMonthlyStatistics', value);
        },
    },

    created() {
        document.title = this.setTitle(this.$t('profile.profile'));
        this.$store.dispatch('stat/fetchMonthlyStatistics');
        if (this.selectedApiAccount) {
            this.$store.dispatch('stat/fetchApiUsersMonthlyStatistics', this.selectedApiAccount);
        }
        this.$store.dispatch('apiAccount/fetchList');
    },

    destroyed() {
        this.$store.dispatch('stat/emptyMonthlyStatistics');
        this.$store.dispatch('stat/emptyApiUsersMonthlyStatistics');
    }
};
</script>

<template>
    <Layout>
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">{{ $t('profile.monthly_stat') }}</h4>
            </div>
            <div class="card-body">
                <!-- Table -->
                <div class="table-responsive mb-0">
                    <b-table
                        :items="items"
                        :fields="fields"
                        responsive="sm"
                        :per-page="smsPagination.perPage"
                        :current-page="smsPagination.currentPage"
                        :sort-by.sync="smsPagination.sortBy"
                        :sort-desc.sync="smsPagination.sortDesc"
                        :busy="smsLoading"
                        hover
                        head-variant="light"
                    >
                        <template #table-busy>
                            <div class="text-center">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="visually-hidden">{{ $t('table.loading') }}...</span>
                                </div>
                            </div>
                        </template>
                        <template #cell(actions)="data">
                            <b-button size="sm" variant="light" v-b-tooltip.hover.topleft="$t('profile.download')" @click="download(data.item.id)"><i class="fas fa-download"></i></b-button>
                        </template>
                    </b-table>
                </div>
                <div class="row">
                    <div class="col">
                        <div
                            class="dataTables_paginate paging_simple_numbers float-end"
                        >
                            <ul class="pagination pagination-rounded mb-0">
                                <!-- pagination -->
                                <b-pagination
                                    v-model="smsPagination.currentPage"
                                    :total-rows="rows"
                                    :per-page="smsPagination.perPage"
                                ></b-pagination>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">{{ $t('profile.monthly_user_stat') }}</h4>
                <b-row class="mt-4">
                    <b-form-group
                        id="api-account"
                        class="row"
                        :label="$t('profile.api_account')"
                        label-for="api-account-select"
                        label-cols="12"
                        label-cols-lg="3"
                        content-cols="12"
                        content-cols-lg="9"
                    >
                        <b-form-select id="api-account-select" class="form-select" size="sm" v-model="selectedApiAccount" :options="apiAccounts" value-field="m_id" text-field="fullname">
                            <template #first>
                                <b-form-select-option :value="null" disabled>{{ $t('profile.please_select_api_accoount') }}</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                </b-row>
            </div>
            <div class="card-body">
                <!-- Table -->
                <div class="table-responsive mb-0">
                    <b-table
                        :items="users"
                        :fields="fields"
                        responsive="sm"
                        :per-page="userPagination.perPage"
                        :current-page="userPagination.currentPage"
                        :sort-by.sync="userPagination.sortBy"
                        :sort-desc.sync="userPagination.sortDesc"
                        :busy="apiUsersLoading"
                        show-empty
                        :empty-text="selectedApiAccount === null ? $t('profile.please_select_api_accoount') : $t('profile.empty_result')"
                        hover
                        head-variant="light"
                    >
                        <template #table-busy>
                            <div class="text-center">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="visually-hidden">{{ $t('table.loading') }}...</span>
                                </div>
                            </div>
                        </template>
                        <template #cell(actions)="data">
                            <b-button v-b-tooltip.hover.topleft="$t('profile.download')" size="sm" variant="light" @click="download(data.item.id)"><i class="fas fa-download"></i></b-button>
                        </template>
                    </b-table>
                </div>
                <div class="row">
                    <div class="col">
                        <div
                            class="dataTables_paginate paging_simple_numbers float-end"
                        >
                            <ul class="pagination pagination-rounded mb-0">
                                <!-- pagination -->
                                <b-pagination
                                    v-model="userPagination.currentPage"
                                    :total-rows="userRows"
                                    :per-page="userPagination.perPage"
                                ></b-pagination>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
